
import baseConstants from '~/store/base/-constants'
import requestOfferConstants from '~/store/requestOffer/-constants'
import modalConstants from '~/store/modal/-constants'
import MoleculeAvailabilitySpaceFilterResultCombinedSpace
  from "~/components/molecules/project/building/MoleculeAvailabilitySpaceFilterResultCombinedSpace.vue";
import { getElementHalfWidth } from '~/helpers/util';

export default {
  name: 'MoleculeBuildingSelectedSpaceDetails',
  components: {MoleculeAvailabilitySpaceFilterResultCombinedSpace},
  props: {},
  data() {
    return {}
  },
  computed: {
    engine3d() {
      return this.$engine3d || window.engine3d
    },
    clientManager() {
      return this.engine3d.getClientManager()
    },
    storeFilters() {
      return this.$store.state.availability.filters
    },
    filteredBuildingData() {
      return this.$store.state.availability.project.find((b) => b.id === this.storeFilters.buildingId)
    },
    filteredFloorData() {
      return this.$store.state.availability.building.find((f) => f.id === this.storeFilters.floorId)
    },
    selectedSpace() {
      return this.$store.state.building.space.spaceData
    },
    hasSelectedSpace() {
      return !!this.selectedSpace
    },
    requestedSpaces() {
      return this.$store.state.requestOffer.spaces
    },
    spaceIsRequested() {
      return this.requestedSpaces.find((space) => space.id === this.selectedSpace.id)
    },
    projectFeatures() {
      return this.$store.getters?.featuresForProject(this.activeProject)
    },
    disableList() {
      return this.projectFeatures?.disableMyList
    },
    listSpaceDetails() {
      return this.projectFeatures?.listSpaceDetails || ['sqm', 'people']
    },
    buildings() {
      return this.$store.state.project.project.buildings
    },
    floors() {
      return this.$store.state.project.project.floors
    },
    activeProject() {
      return this.$store.state.project.activeProject
    },
    isMenuOpen() {
      return this.$store.state.base.isAvailabilityMenuOpen
    },
    isEmbedPath() {
      return this.$route.path.includes('/embed')
    },
  },
  watch: {
    selectedSpace: function(space) {
      if (!space && this.$refs.rootRef) {
        this.$refs.rootRef.style.display = 'none';
      }
    }
  },
  mounted() {
  },
  methods: {
    async clearSelectedSpace() {
      console.log(this.selectedSpace);
      const elementHalfWidth = getElementHalfWidth('#organism-sidebar-project');
      const offsetValueX = (this.isMenuOpen && window.innerWidth > 900) ? -elementHalfWidth : 0;
      const offsetValueY = window.innerWidth <= 900 ? -50 : 0;
      const offsetTarget = { x: offsetValueX, y: offsetValueY };
      this.clientManager?.cameraFocusTo(this.selectedSpace?.floor?.building?.code.toLowerCase(), this.selectedSpace?.floor?.code, offsetTarget);
      return this.$store.dispatch('building/viewSpace', {})
    },
    removeSpaceFromCart(space) {
      if (window && 'analyticsLayer' in window) {
        window.analyticsLayer.send({
          event: 'remove_space_from_list',
          payload: {
            zone: 'space',
            space
          }
        })
      }
      this.$store.dispatch(
        requestOfferConstants.withNamespace(requestOfferConstants.action.REMOVE_SPACE),
        space
      )
    },
    addSpaceToCart(space) {
      if (window && 'analyticsLayer' in window) {
        window.analyticsLayer.send({
          event: 'add_space_to_list',
          payload: {
            zone: 'space',
            space
          }
        })
      }
      this.$store.dispatch(
        requestOfferConstants.withNamespace(requestOfferConstants.action.ADD_SPACE),
        space
      )
    },
    addSpaceToCartAndOpenRequestOffer(selectedSpace) {
      if (window && 'analyticsLayer' in window) {
        window.analyticsLayer.send({
          event: 'request_offer_open',
          payload: {
            zone: 'building'
          }
        })
      }
      this.$store.dispatch(
        requestOfferConstants.withNamespace(requestOfferConstants.action.ADD_SPACE),
        selectedSpace
      )
      this.$store.dispatch(modalConstants.withNamespace(modalConstants.action.CHANGE_MODAL_STATE), {
        showModal: true,
        modalType: 'request-offer'
      })
      document.body.classList.add('disable-scroll')
    },
    exploreSpace(e) {
      e.preventDefault();
      e.stopPropagation();
      const space = {
        ...this.selectedSpace
      }
      space.building = this.buildings.find((b) => b.id === this.selectedSpace.building_id);
      space.floor = this.floors.find((f) => f.id === this.selectedSpace.floor_id);

      if (window && 'analyticsLayer' in window) {
        window.analyticsLayer.send({
          event: 'explore_space_trigger',
          payload: {
            zone: 'building',
            spaceCode: space.space_code,
            buildingCode: space.building.code,
            floorCode: space.floor.code,
          }
        })
      }
      if (!this.isEmbedPath) {
        this.$router.push({
          path: `/project/${this.activeProject}/space/${space.id}#building`
        })
      } else {
        this.$router.push({
          path: `/embed/${this.activeProject}/space/${space.id}`
        })
      }
    }
  }
}
